import Bugsnag from '@bugsnag/js';

const langID = 'en-US';

const currencyFormat = (
  price: number,
  options?: Intl.NumberFormatOptions,
) => {
  const opts = { style: 'currency', currency: 'USD', ...options };
  try {
    const priceFormat = (
      new Intl.NumberFormat(langID, opts)
    ).format(price);
    return priceFormat;
  } catch (error) {
    // Safari thown error if maximumFractionDigits = 0
    if (
      error instanceof RangeError
      && typeof options?.maximumFractionDigits === 'number'
      && options?.maximumFractionDigits === 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const priceFormat = (new Intl.NumberFormat(langID)).format(price);
      return `$${priceFormat}`;
    }
    Bugsnag.notify(
      error as Error,
      (event) => event.addMetadata('price', {
        price,
        explanation: 'No format added to price. It must be an old browser',
      }),
    );
    return `${price}`;
  }
};

export default currencyFormat;

/**
 * If price is like $23.00 it will return just $23
 */
export const getCurentFormat = (
  price: number,
  options?: Intl.NumberFormatOptions,
) => {
  const priceFormat = currencyFormat(price, options);
  const prices = priceFormat.split('.');
  if (prices[1] && prices[1] === '00') {
    return prices[0];
  }
  return priceFormat;
};

export const getCurentFormatOrUndefined = (
  price?: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (!price) {
    return undefined;
  }
  return getCurentFormat(price, options);
};

export const getCurentFormatOrZero = (
  price?: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (!price) {
    return getCurentFormat(0, options);
  }
  return getCurentFormat(price, options);
};
