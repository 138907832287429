import {
  string, any, array, bool,
} from 'vue-types';

const props = {
  id: string(),
  ariaDescribedby: string(),
  type: string().def('text'),
  name: string(),
  placeholder: string(),
  modelValue: any(),
  class: any(),
  errors: array().def([]),
  defaultClass: any().def('border text-left px-4 py-1 h-10 rounded w-full'),
  errorClass: any().def('border-red-500'),
  noErrorClass: any().def('border-gray-400'),
  required: bool(),
  disabled: bool(),
  readonly: bool(),
  autocomplete: string(),
  inputmode: string<'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'>(),
};

export default props;
