<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
  <Select
    v-model="inputValue"
    :name="name"
    :class="$props.class"
    :errors="errors"
    :default-class="defaultClass"
    :error-class="errorClass"
    :no-error-class="noErrorClass"
    :required="required"
    @ref="(values) => $emit('ref', values)"
    @focus="(values) => $emit('focus', values)"
    @blur="(values) => $emit('blur', values)"
    @input="(values) => $emit('input', values)"
  >
    <template
      v-for="state in allStates"
      :key="state.value"
    >
      <option v-if="!availables || availables[state.value]" :value="state.value">
        {{ state.label }}
      </option>
    </template>
  </Select>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';

import selectProps from '../selectProps';
import Select from '../Select.vue';
import states, { extraStates } from '@/plugin/data/states';

const getState = (value?: string): string | undefined => {
  if (!value) {
    return undefined;
  }
  return states.find((state) => state.value === value)?.value;
};

export default defineComponent({
  name: 'AutocompleteState',
  components: {
    Select,
  },
  props: {
    ...selectProps,
    extraStates: Boolean,
  },
  emits: [
    'update:modelValue',
    'focus',
    'blur',
    'input',
    'ref',
  ],
  setup(props, { emit }) {
    const inputValue = ref(getState(props.modelValue));
    watch(inputValue, () => { emit('update:modelValue', inputValue.value); });
    watch(() => props.modelValue, () => { inputValue.value = props.modelValue; });

    const allStates = computed(() => (props.extraStates
      ? [...states, ...extraStates]
      : states));
    return {
      allStates,
      inputValue,
    };
  },
});
</script>
