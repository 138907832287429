<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
  <input
    ref="refInput"
    v-model="inputValue"
    :name="name"
    :placeholder="placeholder"
    :type="type"
    :min="min"
    :required="required"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :inputmode="inputmode"
    :class="[
      defaultClass,
      $props.class,
      { [errorClass]: errors.length > 0 },
      { [noErrorClass]: inputValue && errors.length === 0 },
    ]"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @input="$emit('input')"
  >
</template>

<script lang="ts">
import {
  defineComponent, computed, ref, watch,
} from 'vue';
import { oneOfType } from 'vue-types';

import props from './inputProps';

export default defineComponent({
  name: 'Input',
  props: {
    ...props,
    min: oneOfType([String, Number]),
  },
  emits: [
    'update:modelValue',
    'ref',
    'focus',
    'blur',
    'input',
  ],
  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const refInput = ref();
    watch(refInput, () => {
      emit('ref', refInput.value);
    });
    return {
      inputValue,
      refInput,
    };
  },
});
</script>
