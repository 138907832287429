<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
  <select
    ref="refSelect"
    v-model="inputValue"
    :name="name"
    :class="[
      defaultClass,
      $props.class,
      { [errorClass]: errors.length > 0 },
      { [noErrorClass]: inputValue && errors.length === 0 },
    ]"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @input="$emit('input')"
  >
    <slot />
  </select>
</template>

<script lang="ts">
import {
  defineComponent, computed, ref, watch,
} from 'vue';

import props from './selectProps';

export default defineComponent({
  name: 'Select',
  props,
  emits: [
    'update:modelValue',
    'focus',
    'blur',
    'input',
    'ref',
  ],
  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const refSelect = ref();
    watch(refSelect, () => {
      emit('ref', refSelect.value);
    });
    return {
      inputValue,
      refSelect,
    };
  },
});
</script>
