<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
  <Input
    v-model="inputValue"
    v-maska
    data-maska="#### #### #### ####"
    v-bind="inputPropsValues"
    :type="type"
    :placeholder="placeholder"
    inputmode="numeric"
    autocomplete="off"
    @focus="focus"
    @blur="blur"
    @ref="getRefValue"
  />
  <div :class="['absolute top-0 h-full right-0', size]">
    <div class="w-full h-full flex items-center">
      <CreditCard :credit-card-type="creditCardType" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, VNodeRef,
} from 'vue';
import getCCType from 'credit-card-type';
import { any } from 'vue-types';
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';
import { vMaska } from 'maska';

import CreditCard from './cards/CreditCard.vue';
import Input from '../Input.vue';
import inputProps from '../inputProps';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { type: typeNoUsed, ...props } = inputProps;

const getCreditCardType = (creditCardNumber?: string): CreditCardTypeCardBrandId => {
  const generic = 'generic';
  if (!creditCardNumber) {
    return generic as CreditCardTypeCardBrandId;
  }
  const ccArray = getCCType(creditCardNumber);
  return (ccArray.length > 0)
    ? ccArray[0].type as CreditCardTypeCardBrandId
    : generic as CreditCardTypeCardBrandId;
};

export default defineComponent({
  name: 'CreditCardNumber',
  components: {
    CreditCard,
    Input,
  },
  directives: { maska: vMaska },
  props: {
    ...props,
    size: any().def('w-16 pr-2'),
  },
  emits: ['update:modelValue', 'ref'],
  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const creditCardType = computed(() => getCreditCardType(inputValue.value));
    const type = ref('password');
    const focus = () => {
      type.value = 'tel';
    };
    const blur = () => {
      type.value = 'password';
    };
    const getRefValue = (value: VNodeRef) => {
      emit('ref', value);
    };
    return {
      inputValue,
      creditCardType,
      focus,
      blur,
      type,
      getRefValue,
    };
  },
  computed: {
    inputPropsValues() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { size, ...inputPropsValues } = this.$props;
      return inputPropsValues;
    },
  },
});
</script>
